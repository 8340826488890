html {
  padding: 0;
  margin: 0;
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;

  background: #24242c;
  color: #e6e6e6;

  font-size: 26px;
}

#root {
  height: 100vh;
  width: 100vw;
}

@media screen and (max-width: 600px) {
  body {
    font-size: 4.6vw;
  }
}

* {
  font-family: 'Baloo 2', 'Maven Pro', 'Varela Round', 'Ubuntu', 'Roboto', sans-serif;
  line-height: 1.2;
  letter-spacing: 0.035em;
  box-sizing: border-box;
}

.material-icons {
  user-select: none;
  font-size: 1em;
}

header .material-icons {
  font-size: 1.5em;
}

button {
  background: #0000;
  outline: none;
  border: none;
  cursor: pointer;
}

h1 {
  font-size: 1.7em;
  margin: 0;
}

a {
  text-decoration: none;
  color: white;
}

/* 7 is lucky number they say */
@keyframes gradient7 {
  0% {
    background-position: 0% 50%;
  }
  99.9991% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
